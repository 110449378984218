<template>
	<div class="container">
		<Header :flag="flag" />
		<div class="banner">
			<div class="banner-content">
				<div class="banner-content-l">
					<span class="ytk-title">易视App</span>
					<br/>
					<span class="ytk-subtitle">海量视频模板帮您轻松创作视频 节约创作时间 提高业务成果</span>
					<br/>
					<span class="ytk-intro">易视致力于帮助企业实现短视频创作，精准销售;并实现对线索的快速触达，精准快速的锁定客户;从视频创作、发布、管理、评论、转化到留存的整个生命周期的管理。</span>
					<!-- <div >
						<el-button type="primary" class="btn-ytk" @click="qrcodeVisible = true">立即体验</el-button>
					</div> -->
				</div>
				<div class="banner-content-r">
					<!-- <div class="buttom-qrcode">
						<span class="app-title">
							扫描二维码 立即下载体验
						</span>
						<br>
						<div class="buttom-ytkios">
							<img src="../assets/ytk_qrcode.png" width="110px" height="110px" />
							<br>
							<span class="buttom-ytkios-text">IOS</span>
						</div>
						<div class="buttom-ytkandroid">
							<img src="../assets/ytk_qrcode.png" width="110px" height="110px" />
							<br>
							<span class="buttom-ytkandroid-text">Android</span>
						</div>
					</div> -->
				</div>
			</div>
			<el-dialog
			  width="30%"
			  title="扫一扫下载体验"
			  :visible.sync="qrcodeVisible"
			  append-to-body>
			  <div class="ytk-qr-code">
				  <img src="../assets/ytk_qrcode.png" />
			  </div>
			</el-dialog>
		</div>
		 <div class="main">
			 <div class="question-title">
				 <h2 class="headlineTwo ">
					 企业短视频营销的<em style="color: #007AFF;font-size: 40px;">困扰</em>
				 </h2>
			 </div>
			<div class="question">
				<ul class="question-list">
					<li class="listItem" v-for="item in questionList">
						<div class="itemLink">
							<div class="itemHeader">
								<h6 class="headerText">{{item.title}}：</h6>
							</div>
							<div class="bodyText">
								{{item.intro}}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		
		
		<div class="buttom-container">
			 <div class="buttom-content">
				<span>易视App帮助您解决企业短视频运营困扰</span>  
			 </div>
		</div>
		<div class="ytk-target">
			<div class="target-title">
				<span class="target-title-text">易视App <span style="color: #007AFF;font-size: 48px;font-weight:600">9</span> 大功能</span> 
				<br/>
				<span class="target-subtitle-text">让您轻松实现短视频运营，提升目标业绩</span>
			</div>
			<div class="target-content">
				<div class="target-left">
					<div class="target-left-title">
						随时随地，轻松玩转短视频
					</div>
					<div class="left-content">
						<div class="left-box" v-for="item in ytkTargets">
							<div class="left-title">{{item.title}}</div>
							<div class="left-intro">
								<span>{{item.intro}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="target-right">
					<img src="../assets/svg/illustration-5.svg" class="img-fluid img-center"/>
				</div>
			</div>
			<div class="target-content1">
				<div class="target-left1">
					<img src="../assets/svg/illustration-6.svg" class="img-fluid img-center" style="height: 450px;"/>
				</div>
				<div class="target-right1">
					<div class="right1-box" v-for="item in ytkTargets1">
						<div class="right1-title">{{item.title}}</div>
						<div class="right1-intro">
							<span>{{item.intro}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="target-content2">
				<div class="target-left2">
					<div class="left2-box" v-for="item in ytkTargets2">
						<div class="left2-title">{{item.title}}</div>
						<div class="left2-intro">
							<span>{{item.intro}}</span>
						</div>
					</div>
				</div>
				<div class="target-right2">
					<img src="../assets/svg/illustration-7.svg" class="img-fluid img-center"/>
				</div>
			</div>
		</div>
		<Footer  />
	</div>	
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import { Swiper, SwiperSlide,} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	export default {
		metaInfo: {
			title: '蓝莓科技-易视App-高质量视频解决方案',
			meta: [
			  {
				name: 'keywords',
				content: '易视,拓客引流,意向客户,短视频引流'
			  },
			  {
				name: 'description',
				content: '蓝莓科技易视App是提供高效高质视频自动生成，一键发布短视频平台方案，让意向用户与商家建立信任，帮助企业更好的留住意向用户。'
			  }
			]
		},
		name: 'Ys',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'solution',
				qrcodeVisible:false,
				questionList:[
					// {
					// 	title: '没有认证企业号：' ,
					// 	intro: '属于个人号，相当于朋友圈在发、名称不唯一、不能强营销“讲产品/公司”。'
					// },	
					{
						title: '创作遇到瓶颈：' ,
						intro: '没有创意：创作时没有想法，不知道拍什么内容。'
					},
					{
						title: '不会剪辑视频：' ,
						intro: '收集了很多素材，但是不会剪辑，很多剪辑的软件不会操作，或者学习成本高。'
					},
					{
						title: '不会拟标题：' ,
						intro: '标题是视频的主题浓缩，拟出好的标题会帮助视频获取更高的播放量，这就需要我们费时费力地去想。'
					},
					{
						title: '视频审核通过率低：' ,
						intro: '不清楚平台规则，容易触发违禁、违规词，导致视频审核不通过。'
					},
					{
						title: '作品集不方便实时监控数据：' ,
						intro: '对于发布的多个作品，不能便捷的查看到它们的发布数据。'
					}
				],
				ytkTargets: [
					{
						title: '1、账号矩阵' ,
						intro: '支持绑定主流短视频平台的多个账号，让运营1+1大于等于2'
					},
					 {
						title: '2、一键使用视频模板创作' ,
						intro: '为您准备海量视频模板，提供各个行业热门、精美视频模板，轻松创作高质量模板随手拍大片。'
					 }, 
					 {
						title: '3、文案提取' ,
						intro: '一键提取视频文案，轻轻松松搞定视频内容，提高创作灵感，解决没有拍摄思路问题。'
					 }
				   ],
				ytkTargets1: [
					 {
						title: '4、一键生成标题' ,
						intro: '“行业+关键词”一键生成标题，为你提供多个标题参考，让您的视频更加精准、快速、高质，高效'
					 },
					 {
						title: '5、智能投放' ,
						intro: '打通多个头部平台，视频全网分发，多场景视频流量全网获取'
					 },
					 {
						title: '6、视频裂变' ,
						intro: '视频智能生成，视频播放量、点赞量、评论量、转发量持续提高'
					 }
				   ],
				ytkTargets2: [
					  {
						title: '7、数据分析' ,
						intro: '通过视频播放量、点赞量、评论量、转发量数据分析，收集粉丝喜爱的视频内容，精准投放，获取更多的意向用户'
					  } ,
					 {
						title: '8、回复评论' ,
						intro: '接收视频评论内容，快速回复用户，促进互动，让您拓客快人一步!'
					 } ,
					 {
						title: '9、违规词查询' ,
						intro: '一键识别文案内容，检测文案是否存在违规、违禁词，让视频快速通过短视频平台审核'
					 } 
					],
				
			}
		}
	}
</script>

<style scoped lang="less">

	.ytk-qr-code{
		text-align: center;
		img{
			width:100%;
			height: 100%;
		}
	}
	.banner{
		padding-top: 80px;
		height: 450px;
		background-image: url(../assets/img/banner_ys.jpeg);
		background-position: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		.banner-content{
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			width: 1200px;
			margin: auto;
			.banner-content-l{
				width: 600px;
				.ytk-title{
					display: inline-block;
					font-size: 24px;
					color: #ffffff;
					margin-top: 45px;
				}
				.ytk-subtitle{
					display: inline-block;
					margin-top: 18px;
					width: 350px;
					font-size: 28px;
					color: #ffffff;
					line-height: 40px;
				}
				.ytk-intro{
					display: inline-block;
					margin-top: 16px;
					width: 600px;
					font-size: 16px;
					color: #ffffff;
					line-height: 35px;
				}
				.el-button{
					font-size: 18px !important;
				}
				.btn-ytk{
					margin-top: 30px;
					height: 50px;
					width: 200px;
					color: #FFFFFF;
					span{
						font-size: 16px;
					}
				}
			}
			.banner-content-r{
				width: 600px;
				display: flex;
				display: -webkit-flex;
				flex-direction:row;
				justify-content:flex-end;
				.buttom-qrcode{
					width: 280px;
					// margin: auto;
					margin-top: 180px;
					height: 170px;
					text-align: center;
					display: flex;
					display: -webkit-flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content:flex-end;
					.app-title{
						display: inline-block;
						margin-bottom: 20px;
						width: 280px;
						font-size: 16px;
						font-weight: 600;
						color: #fff;
						text-align: center;
					}
					.buttom-ytkios{
						width: 130px;
						.buttom-ytkios-text{
							display: inline-block;
							margin-top: 10px;
							font-size: 20px;
							color: #fff;
							text-align: center;
						}
					}
					.buttom-ytkandroid{
						width: 130px;
						.buttom-ytkandroid-text{
							display: inline-block;
							margin-top: 10px;
							font-size: 20px;
							color: #fff;
							text-align: center;
						}
					}
				}
			}
		}
	}
	.main{
		background-color: white;
		background-image: url(../assets/svg/cluvio-geo-pattern.svg),linear-gradient(225deg, rgba(21,152,244,0.05) 0%, rgba(255,215,108,0.05) 100%);
		background-repeat: repeat;
		.question-title{
			text-align: center;
			padding-top: 70px;
			margin-bottom: 60px;
			// font-size: 38px;
			// font-weight: 600;
			// color: #131313;
			// letter-spacing: 6px;
			.headlineTwo{
				margin:0;
				font-size: 2.5rem;
				letter-spacing: -.01em;
				line-height: 1.25em;
				font-family:"gilmer-web","Gilmer",Geneva,Tahoma,Helvetica,Verdana,sans-serif;;
				font-weight: 700;
				letter-spacing: 6px;
			}
		}
		.question{
			text-align: center;
			width: 1200px;
			margin: auto;
			margin-top: 70px;
			padding-bottom: 60px;
			.question-list{
				list-style: none;
				margin: 0 auto;
				padding: 0;
				max-width: 900px;
				.listItem{
					width: 100%;
					border: 1px solid gray;
					margin: 16px 0;
					transition: box-shadow .2s ease;
					.itemLink{
						padding: 44px;
						display: flex;
						justify-content: space-between;
						color: #000;
						.itemHeader{
							margin-right: 49px;
							flex-basis: 30%;
							text-align: left;
							margin-bottom: 0;
							height: 60px;
							.headerText{
								font-size: 1.5rem;
								line-height: 1.417em;
								letter-spacing: -.004em;
								font-family: "gilmer-web","Gilmer",Geneva,Tahoma,Helvetica,Verdana,sans-serif;
								font-weight: 700;	
							}
						}
						.bodyText{
							text-align: left;
							flex-basis: 70%;
							color:#343536;
							font-size: 1.25rem;
							line-height: 1.55em;
							letter-spacing: .01em;
							font-family:  "metro-web","Metro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;;
							font-weight: 400;
						}
					}
				}
				.listItem:hover{
					box-shadow:
					0 1.9px 2.5px rgba(0, 0, 0, 0.057),
					0 5px 6.1px rgba(0, 0, 0, 0.076),
					0 10.1px 11.4px rgba(0, 0, 0, 0.086),
					0 19.2px 19.8px rgba(0, 0, 0, 0.092),
					0 38.4px 34.8px rgba(0, 0, 0, 0.1),
					0 101px 74px rgba(0, 0, 0, 0.13);
				}
			} 
			  
		}
	}
	.ytk-target{
		margin-top: 40px;
		height:auto;
		padding-bottom: 60px;
		background-color: #f7f7f7;
		.img-center {
		    display: block;
		    margin-left: auto;
		    margin-right: auto;
		}
		.img-fluid {
		    max-width: 100%;
		    height: auto;
		}
		img {
		    vertical-align: middle;
		    border-style: none;
		}
		.target-title{
			padding-top: 63px;
			text-align: center;
			.target-title-text{
				font-size: 40px;
				font-weight: 600;
				color: #131313;
			}
			.target-subtitle-text{
				font-size: 30px;
				color: #131313;
				line-height: 70px;
			}
		}
		.target-content{
			width: 1200px;
			margin: auto;
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left{
				width: 600px;
				.target-left-title{
					padding-top: 10px;
					font-size: 30px;
					color: #217bfb;
				}
				.left-content{
					margin-top: 70px;
					.left-box{
						width: 560px;
						max-height: 220px;
						margin: 20px 20px 20px 20px;
						.left-title{
							line-height: 45px;
							font-size: 20px;
							font-weight: 600;
							color: rgba(18, 139, 251, 0.8);
						}
						.left-intro{
							width: 560px;
							text-align: left;
							padding: auto;
							padding-top: 20px;
							span{
								font-size: 16px;
								line-height: 35px;
							}
						}
					}
				}
			}
			.target-right{
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
		}
		.target-content1{
			width: 1200px;
			margin: auto;
			margin-top: 30px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left1{
				margin-top: 80px;
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
			.target-right1{
				margin-top: 80px;
				width: 600px;
				.right1-box{
					width: 560px;
					max-height: 220px;
					margin: 20px 20px 20px 20px;
					.right1-title{
						line-height: 45px;
						font-size: 20px;
						font-weight: 600;
						color: rgba(18, 139, 251, 0.8);
					}
					.right1-intro{
						width: 560px;
						text-align: left;
						padding: auto;
						span{
							font-size: 16px;
							line-height: 35px;
						}
					}
				}
			}
		}
		.target-content2{
			width: 1200px;
			margin: auto;
			margin-top: 80px;
			display: -webkit-flex;
			display: flex;
			flex-direction:row;
			justify-content:center;
			.target-left2{
				width: 600px;
				.left2-box{
					width: 560px;
					max-height: 220px;
					margin: 20px 20px 20px 20px;
					.left2-title{
						line-height: 45px;
						font-size: 20px;
						font-weight: 600;
						color: rgba(18, 139, 251, 0.8);
					}
					.left2-intro{
						width: 560px;
						text-align: left;
						padding: auto;
						span{
							font-size: 16px;
							line-height: 35px;
						}
					}
				}
			}
			.target-right2{
				text-align: center;
				width: 600px;
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
			
		}
	}
	.ytk-message{
		margin-top: 73px;
		display: flex;
		display: -webkit-flex;
		flex-direction:row;
		justify-content:center;
		flex-wrap:wrap;
		height: auto;
		.ytk-message-l{
			width: 600px;
			.ytk-message-title{
				font-size: 30px;
				color: #217bfb;
			}	
			.ytk-message-content{
				margin-top: 40px;
				span{
					line-height: 45px;
					font-size: 20px;
					font-weight: 600;
					color: rgba(18, 139, 251, 0.8);
				}
				.ytk-message-intro{
					margin-top: 20px;
					width: 520px;
					text-align: left;
					font-size: 16px;
					line-height: 35px;
				}
			}
		}
		.ytk-message-r{
			text-align: center;
			width: 600px;
			margin-top: 70px;
			.ad-box-content{
				width: 400px;
				margin: auto;
			}
		}
	}	
	.ytk-ad{
		margin-top: 60px;
		height:auto;
		padding-bottom: 60px;
		background-color: #f7f7f7;
		.ytk-main{
			display: flex;
			display: -webkit-flex;
			flex-direction:row;
			justify-content:center;
			flex-wrap:wrap;
			height: auto;
			margin-bottom: 40px;
			.ad-box{
				margin-top: 80px;
				height: 800px;
				width: 600px;
				.ad-box-content{
					width: 600px;
					.ad-title{
						font-size: 28px;
						line-height: 40px;
						font-weight: 600;
					}
					.ad-content{
						margin-top: 40px;
						span{
							line-height: 45px;
							font-size: 20px;
							font-weight: 600;
							color: rgba(18, 139, 251, 0.8);
						}
						.ad-intro{
							width: 520px;
							font-size: 16px;
							line-height: 35px;
							padding-top: 20px;
						}
						
					}
				}
				.ad-box-img{
					width: 400px;
					margin: auto;
				}
			}
		}
	}
	.buttom-container{
		height: 200px;
		background-image: url(../assets/img/zs_img2.png);
		background-repeat: no-repeat;
		margin: auto;
		.buttom-content{
			height:100%;
			display:table;
			width:100%;
			span{
				font-size: 38px;
				color: white;
				font-weight: 600;
				font-family: ;
				display: table-cell;
				text-align: center;
				vertical-align: middle;
				letter-spacing: 6px;
			};
		}
	}
		
</style>
